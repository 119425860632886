// @flow
export let AssetTypeDict = {
  RESTFUL_API: ('RESTFUL_API': 'RESTFUL_API'),
  SINGLE_PAGE_WEB_APP: ('SINGLE_PAGE_WEB_APP': 'SINGLE_PAGE_WEB_APP'),
  API_DOMAIN: ('API_DOMAIN': 'API_DOMAIN'),
  CLOUD_RESOURCE: ('CLOUD_RESOURCE': 'CLOUD_RESOURCE'),
  GRAPHQL_API: ('GRAPHQL_API': 'GRAPHQL_API'),
  GRPC_SERVICE: ('GRPC_SERVICE': 'GRPC_SERVICE'),
  GRPC_METHOD: ('GRPC_METHOD': 'GRPC_METHOD'),
};
